import '../../App.css';
import { Card } from 'antd';

import Survey from '../../models/SurveyModel';

import React from 'react';
import Loader from '../Loader';
import ErrorBoundary from '../ErrorBoundary';


interface SurveyViewProps {
    survey: Survey;
}

interface SurveyViewState {
    body?: JSX.Element[];
    error: boolean;
}


export default class SurveyView extends React.Component<SurveyViewProps, SurveyViewState> {
    constructor(props: SurveyViewProps) {
        super(props);
        this.state = {
            error: false
        };
    }

    async componentDidMount() {
        if (!this.props.survey.template_data) await this.props.survey.set_template();
        this.forceUpdate();

        let body;

        if (this.props.survey.template_format === 1) body = undefined;
        else body = this.parse_results()

        this.setState({ body: body });
    }


    parse_results = () => {
        try {
            let responses = this.props.survey.data["results"];
            let elements = [];

            for (let question of this.props.survey.template_data) {

                let result = responses.find((i: any) => i['identifier'] === question["ID"].toString())

                // question left blank or not rendered based on rules
                if (!result) continue;

                switch (question["type"]) {
                    case "Instruction": {
                        break;
                    }
                    case "Completion": {
                        break;
                    }
                    case "QuestionSingleChoice": {
                        const indices = result['results'][0] ? result['results'][0]['choiceAnswers'] : []
                        elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                            <h3>{question['title']}</h3>
                            <p> {question['detailText']}</p>
                            {indices.map((i: any) => <p className='choice' key={i}>{question['choices'][parseInt(i) - 1]}</p>)}
                            <hr />
                        </div>)
                        break;
                    }
                    case "QuestionMultiChoice": {
                        const indices = result['results'][0] ? result['results'][0]['choiceAnswers'] : []
                        elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                            <h3>{question['title']}</h3>
                            <p> {question['detailText']}</p>
                            {indices.map((i: any) => <p className='choice' key={i}>{question['choices'][parseInt(i) - 1]}</p>)}
                            <hr />
                        </div>)
                        break;
                    }
                    case "QuestionText": {
                        elements.push(<div className='survey-step text' key={question["ID"]}>
                            <h3>{question['title']}</h3>
                            <p> {question['detailText']}</p>
                            <p className='choice'> {result['results'][0]['textAnswer']}</p>
                            <hr />
                        </div>)
                        break;
                    }
                    case "QuestionScale": {
                        elements.push(<div className='survey-step scale' key={question["ID"]}>
                            <h3>{question['title']}</h3>
                            <p> {question['detailText']}</p>
                            <p> {question['scaleMin']} ({question['scaleMinText']}) to {question['scaleMax']} ({question['scaleMaxText']})
                                <span className='choice'>{result['results'][0]['scaleAnswer']}</span>
                            </p>
                            <hr />
                        </div>)
                        break;
                    }
                    default: {
                        elements.push(<div className='survey-step scale' key={question["ID"]}>
                            <h3>{question['title']}</h3>
                            {`Unimplemented type ${question["type"]}`}
                            <p> {question['detailText']}</p>
                            <p> {JSON.stringify(result['results'][0])} </p>
                            <hr />
                        </div>)
                        break;
                    }
                }
            }
            return elements;
        } catch (e) {
            console.log("problem parsing results", e)
            this.setState({ error: true });
        }
    }


    render() {
        if (this.state.error) {
            let message = "Error parsing survey response.";
            if (this.props.survey.template_id ===  "UnidentifiedTemplate.Fallback") message = "Unable to parse because no template identifier was supplied."
            else if (this.props.survey.template_id.includes("Fallback")) message = "Unable to parse because template is missing from the server."
           
            return <span className='survey-fallback'>
                <b>{message} Displaying raw data:</b>

                <div className='survey-data'>
                    {JSON.stringify(this.props.survey.data)}
                </div>
            </span>
        }

        else if (!this.props.survey.template_data) return <Loader />

        return (
            <ErrorBoundary fallback={this.props.survey.data}>
                <Card
                    className='user-info'
                >
                    {this.state.body}
                </Card>
            </ErrorBoundary>
        );
    }

};
