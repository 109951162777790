import React from 'react';

import UserDataTabs from './UserDataTabs';
import AppLayout from '../AppLayout';
import UserInfo from './UserInfo';
import Error404 from '../Error404';

import User from '../../models/UserModel';
import Study from '../../models/StudyModel';

import '../../App.css';

import * as API from '../../api';
import { Button } from 'antd';
import { ArrowLeftOutlined, UserSwitchOutlined } from '@ant-design/icons';


interface UserState {
  user?: User;
  error: boolean;
  study?: Study;
}

interface ViewProps {
  current_user: User;
}

export default class UserView extends React.Component<ViewProps, UserState> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: false
    };
  }

  async componentDidMount() {
    const user_id = window.location.pathname.split("/").pop()!;

    const queryParams = new URLSearchParams(window.location.search);
    let study_id: any = queryParams.get("study_id");
    study_id = study_id ? parseInt(study_id) : undefined;

    let study;
    let config_data;

    if (study_id) {
      let study_response = await API.getStudyByID(study_id);
      let config_response = await API.getStudyConfig(study_id, user_id);

      let study_data = await study_response.json();
      if (config_response.ok) config_data = await config_response.json();

      study = new Study(study_data);
      study.config = config_data;
    }

    let user_response = await API.getUserByID(user_id, study_id);

    if (user_response.status !== 200) {
      this.setState({ error: true });
      return;
    }

    let user_data = await user_response.json();

    this.setState({ user: new User(user_data), study: study })
  }

  updateConfig = async (new_config: any) => {
    let new_study = this.state.study;
    new_study!.config = new_config;

    this.setState({ study: new_study });
  }

  exportHealth = async () => { this.downloadCSVFile(await API.exportUserData(this.state.user!.user_id, this.state.study?.study_id)); }
  exportSurveys = async (filetype : "json" | "csv") => { 
    this.downloadSurveys(await API.exportUserSurveys(this.state.user!.user_id, filetype, this.state.study?.study_id), filetype); 
  }

  downloadSurveys = async (response: Response, filetype : "json" | "csv") => {
    if (filetype === "json") {
      this.downloadJSONFile(response)
    }
    else {
      this.downloadZIPFile(response);
    }
  }


  downloadCSVFile = async (response: Response) => {
    let data = await response.blob();
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${new Date().toISOString()}_${this.state.user!.username}_data.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadJSONFile = async (response: Response) => {
    if (response.ok) {
      let data = await response.blob();
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${new Date().toISOString()}_${this.state.user!.username}_surveys.json`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      alert('Unable to download user data due to server error. Please contact an admin.')
    }
  }

  downloadZIPFile = async (response: Response) => {
    if (response.ok) {
      let data = await response.blob();
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${new Date().toISOString()}_${this.state.user!.username}_surveys.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      alert('Unable to download user data due to server error. Please contact an admin.')
    }
  }

  render() {
    if (this.state.error) return <Error404 current_user={this.props.current_user} />;
    if (!this.state.user) return null;

    let crumbs = [{ title: "All Participants", href: '/users' }, { title: this.state.user.full_name }]
    let nav;
    if (this.state.study) {
      crumbs = [{ title: "My Studies", href: '/studies' },
      { title: this.state.study.name, href: `/study/${this.state.study.study_id}` },
      { title: this.state.user.full_name }]
      nav = <span className='study-participant-nav'>
        {/*<Button size='small' href={`/study/${this.state.study.study_id}`} icon={<ArrowLeftOutlined />}>
                  Back to {this.state.study.name}
              </Button>*/}
        <Button size='small' href={`/user/${this.state.user.user_id}`} icon={<UserSwitchOutlined />}>
          Go to full participant page
        </Button>
        {/*<Button size='small' type="text" href={`/user/${this.state.user.user_id}`} icon={<UserSwitchOutlined />}>
                Go to full participant page
            </Button>*/}
      </span>
    }

    return (
      <AppLayout crumbs={crumbs}
        current_user={this.props.current_user}>
        {nav}
        <UserInfo user={this.state.user} study={this.state.study} updateConfig={this.updateConfig} />
        <UserDataTabs user={this.state.user} study={this.state.study}
          exportHealth={this.exportHealth} exportSurveys={this.exportSurveys}
        />
      </AppLayout>
    );
  }
}

