import React from 'react';
import '../../App.css';
import { Popconfirm, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DateText } from '../DateText';
import Survey from '../../models/SurveyModel';
import SurveyTemplateView from './SurveyTemplateView';
import { DeleteTwoTone } from '@ant-design/icons';
import * as API from '../../api';
import User from '../../models/UserModel';


interface SurveyDataTableProps {
  data : Array<any>;
  refresh : Function;
  current_user : User;
}

export default class SurveyTemplatesTable extends React.Component<SurveyDataTableProps, {}> {

  deleteTemplate = async (id : string) => {
    let response = await API.deleteSurveyTemplate(id);
    if (response.ok) this.props.refresh(); 
  }

  columns: ColumnsType<any> = [
    {
      title: 'Survey',
      sorter: (a, b) => a['identifier'].localeCompare(b['identifier']),
      render: s => s['identifier']
    },
    {
      title: 'Created By',
      sorter: (a, b) => new Date(a.created_on!).getTime() - new Date(b.created_on!).getTime(),
      render: s => s['created_by']
    },
    {
      title: 'Created On',
      sorter: (a, b) => new Date(a.created_on!).getTime() - new Date(b.created_on!).getTime(),
      render: (o : Survey) => <DateText date={new Date(o.created_on)}/>
    },
    {
      title: '',
      // FIXME: removing delete functionality for now
      render: (o : any) => o.created_by === this.props.current_user.username ? 
                <Popconfirm title="Confirm delete" onConfirm={() => this.deleteTemplate(o.identifier)} >
                    <DeleteTwoTone className='delete-template' twoToneColor="#eb2f96" style={{'display' : 'none'}}/>
                  </Popconfirm> : ''
    }
  ];

  render() {
    return (
      <Table columns={this.columns} rowKey="created_on" 
             dataSource={this.props.data}
             expandable={{ expandedRowRender : r => <SurveyTemplateView template_data={r}/> }}/>
    );
  }
}



